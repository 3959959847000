import Lenis from '@studio-freight/lenis'
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

let lenis = null

const
    marqueeAnimation = (container, reversed, speed) => {
        let loops = gsap.utils.toArray(container).map((line, i) => {
            const innerElements = container.querySelectorAll('.marquee-js__el'),
                horizontalLoop = (items, config) => {
                    /* Based off GreenSock's pen here: https://codepen.io/GreenSock/pen/jOBBVjr  */

                    items = gsap.utils.toArray(items);
                    config = config || {}
                    let tl = gsap.timeline({
                        repeat: config.repeat,
                        paused: config.paused,
                        defaults: { ease: "none" },
                        onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100)
                    }),
                        length = items.length,
                        startX = items[0].offsetLeft,
                        times = [],
                        widths = [],
                        xPercents = [],
                        curIndex = 0,
                        pixelsPerSecond = (config.speed || 1) * 100,
                        snap = config.snap === false ? v => v : gsap.utils.snap(config.snap || 1), // some browsers shift by a pixel to accommodate flex layouts, so for example if width is 20% the first element's width might be 242px, and the next 243px, alternating back and forth. So we snap to 5 percentage points to make things look more natural
                        totalWidth, curX, distanceToStart, distanceToLoop, item, i
                    gsap.set(items, { // convert "x" to "xPercent" to make things responsive, and populate the widths/xPercents Arrays to make lookups faster.
                        xPercent: (i, el) => {
                            let w = widths[i] = parseFloat(gsap.getProperty(el, "width", "px"))
                            xPercents[i] = snap(parseFloat(gsap.getProperty(el, "x", "px")) / w * 100 + gsap.getProperty(el, "xPercent"))
                            return xPercents[i]
                        }
                    });
                    gsap.set(items, { x: 0 });
                    totalWidth = items[length - 1].offsetLeft + xPercents[length - 1] / 100 * widths[length - 1] - startX + items[length - 1].offsetWidth * gsap.getProperty(items[length - 1], "scaleX") + (parseFloat(config.paddingRight) || 0);
                    for (i = 0; i < length; i++) {
                        item = items[i];
                        curX = xPercents[i] / 100 * widths[i];
                        distanceToStart = item.offsetLeft + curX - startX;
                        distanceToLoop = distanceToStart + widths[i] * gsap.getProperty(item, "scaleX");
                        tl.to(item, {
                            xPercent: snap((curX - distanceToLoop) / widths[i] * 100),
                            duration: distanceToLoop / pixelsPerSecond
                        }, 0)
                            .fromTo(item, { xPercent: snap((curX - distanceToLoop + totalWidth) / widths[i] * 100) }, {
                                xPercent: xPercents[i],
                                duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond,
                                immediateRender: false
                            }, distanceToLoop / pixelsPerSecond)
                            .add("label" + i, distanceToStart / pixelsPerSecond);
                        times[i] = distanceToStart / pixelsPerSecond;

                        /*item.addEventListener("mouseenter", () => gsap.to(tl, {timeScale: 0, overwrite: true}));
                        item.addEventListener("mouseleave", () => gsap.to(tl, {timeScale: 1, overwrite: true}));*/
                    }

                    function toIndex(index, vars) {
                        vars = vars || {};
                        (Math.abs(index - curIndex) > length / 2) && (index += index > curIndex ? -length : length); // always go in the shortest direction
                        let newIndex = gsap.utils.wrap(0, length, index),
                            time = times[newIndex];
                        if (time > tl.time() !== index > curIndex) { // if we're wrapping the timeline's playhead, make the proper adjustments
                            vars.modifiers = { time: gsap.utils.wrap(0, tl.duration()) };
                            time += tl.duration() * (index > curIndex ? 1 : -1);
                        }
                        curIndex = newIndex;
                        vars.overwrite = true;
                        return tl.tweenTo(time, vars);
                    }

                    tl.next = vars => toIndex(curIndex + 1, vars);
                    tl.previous = vars => toIndex(curIndex - 1, vars);
                    tl.current = () => curIndex;
                    tl.toIndex = (index, vars) => toIndex(index, vars);
                    tl.times = times;
                    if (config.reversed) {
                        tl.vars.onReverseComplete();
                        tl.reverse();
                    }
                    return tl;
                }

            horizontalLoop(innerElements, {
                repeat: -1,
                speed: speed ? speed : .5,
                reversed: reversed,
                paddingRight: parseFloat(gsap.getProperty(innerElements[0], 'marginRight', 'px'))
            })
        })
    },
    hero = document.getElementById('hero'),
    heroMaskedTexts = hero.querySelectorAll('.masked-text'),
    heroOverlay = document.getElementById('hero-overlay'),
    marqueeEl = document.querySelector('.marquee-js'),
    crossroadsBanner = document.getElementById('crossroads'),
    rotateImages = document.querySelectorAll('[data-rotate]'),
    scrollBottom = document.getElementById('scroll-bottom'),
    heroAnimation = () => {
        for (const [index, text] of heroMaskedTexts.entries()) {
            gsap.to(text, {
                y: 0,
                duration: 1,
                delay: index / 5,
                onComplete: index === heroMaskedTexts.length - 1 ? () => {
                    scrollBottom.classList.add('animated')
                } : null
            })
        }

        gsap.to(hero, {
            y: 500,
            ease: 'none',
            scrollTrigger: {
                scrub: true,
                trigger: hero,
                start: 'top top',
                end: 'bottom top',
                invalidateOnRefresh: true
            }
        })

        gsap.to(heroOverlay, {
            opacity: .5,
            scrollTrigger: {
                scrub: true,
                trigger: hero,
                start: 'center center',
                end: 'bottom top',
                invalidateOnRefresh: true
            }
        })
    },
    crossroadsAnimation = () => {
        gsap.to(crossroadsBanner, {
            rotate: '-4deg',
            ease: 'ease.out',
            scrollTrigger: {
                scrub: true,
                trigger: crossroadsBanner,
                start: 'top 80%',
                end: 'bottom center',
                invalidateOnRefresh: true
            }
        })
    },
    smoothScrollInit = () => {
        lenis = new Lenis()

        function raf(time) {
            lenis.raf(time)
            requestAnimationFrame(raf)
        }

        requestAnimationFrame(raf)

        lenis.on('scroll', ScrollTrigger.update)
        gsap.ticker.lagSmoothing(0)
    },
    rotateImagesAnimation = () => {
        for (const image of rotateImages) {
            const rotateQty = image.getAttribute('data-rotate')

            gsap.to(image, {
                rotate: rotateQty,
                ease: 'none',
                scrollTrigger: {
                    scrub: true,
                    trigger: image,
                    start: 'top bottom',
                    end: 'bottom top',
                    invalidateOnRefresh: true
                }
            })
        }
    }


document.addEventListener('DOMContentLoaded', () => {
    window.scrollTo(0, 0)

    heroAnimation()

    smoothScrollInit()

    rotateImagesAnimation()

    crossroadsAnimation()

    marqueeAnimation(marqueeEl, null, 1)
})